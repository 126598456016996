<template>
  <div>
    <el-row
      style="display: flex; margin-bottom: 1rem"
      v-if="supermetrics_counts"
    >
      <el-col style="display: flex; align-items: center">
        Supermetrics Accounts (limit {{ supermetrics_counts.limit }}):
        <el-tag
          style="margin-left: 0.5rem; display: flex; align-items: center"
          :type="countStyles['AW'].type"
        >
          <font-awesome-icon
            :icon="countStyles['AW'].icon"
            full-width
          ></font-awesome-icon>
          <img
            style="margin-left: 0.25rem; margin-right: 0.25rem"
            :src="getServiceLogo('google-display')"
            class="service-logo"
          />
          {{ supermetrics_counts["AW"] }}
        </el-tag>
        <el-tag
          style="margin-left: 0.5rem; display: flex; align-items: center"
          :type="countStyles['FA'].type"
        >
          <font-awesome-icon
            :icon="countStyles['FA'].icon"
            full-width
          ></font-awesome-icon>
          <img
            style="margin-left: 0.25rem; margin-right: 0.25rem"
            :src="getServiceLogo('facebook-display')"
            class="service-logo"
          />
          {{ supermetrics_counts["FA"] }}
        </el-tag>
        <el-tag
          style="margin-left: 0.5rem; display: flex; align-items: center"
          :type="countStyles['AC'].type"
        >
          <font-awesome-icon
            :icon="countStyles['AC'].icon"
            full-width
          ></font-awesome-icon>
          <img
            style="margin-left: 0.25rem; margin-right: 0.25rem"
            :src="getServiceLogo('bing-sem')"
            class="service-logo"
          />
          {{ supermetrics_counts["AC"] }}
        </el-tag>
      </el-col>
      <el-col style="flex: 1; text-align: right">
        <el-button
          type="primary"
          plain
          medium
          @click="$router.push({ name: 'admin_supermetrics' })"
          >Supermetrics Settings</el-button
        >
      </el-col>
    </el-row>
    <el-card>
      <tracker-table></tracker-table>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import TrackerTable from "@/components/Tables/TrackerTable"
import TrackerServiceLogos from "@/mixins/TrackerServiceLogos"

export default {
  name: "TrackerAdmin",
  mixins: [TrackerServiceLogos],

  components: {
    TrackerTable,
  },
  computed: {
    ...mapGetters({
      supermetrics_counts: "companies/supermetrics_counts",
    }),
    countStyles() {
      if (!this.supermetrics_counts) return
      return {
        AW: {
          type: this.countType(
            this.supermetrics_counts["AW"],
            this.supermetrics_counts.limit
          ),
          icon: this.countIcon(
            this.supermetrics_counts["AW"],
            this.supermetrics_counts.limit
          ),
        },
        FA: {
          type: this.countType(
            this.supermetrics_counts["FA"],
            this.supermetrics_counts.limit
          ),
          icon: this.countIcon(
            this.supermetrics_counts["FA"],
            this.supermetrics_counts.limit
          ),
        },
        AC: {
          type: this.countType(
            this.supermetrics_counts["AC"],
            this.supermetrics_counts.limit
          ),
          icon: this.countIcon(
            this.supermetrics_counts["AC"],
            this.supermetrics_counts.limit
          ),
        },
      }
    },
  },
  methods: {
    countType(count, limit) {
      if (count > limit) {
        return "danger"
      } else if (count / limit > 0.8) {
        return "warning"
      } else {
        return "success"
      }
    },
    countIcon(count, limit) {
      if (count > limit) {
        return "times"
      } else if (count / limit > 0.8) {
        return "alert"
      } else {
        return "check"
      }
    },
  },
}
</script>
