<template>
  <div>
    <div class="table-container">
      <h3 class="table-title">Tracker Settings</h3>
      <div class="table-toolbar">
        <el-input v-model="search" prefix-icon="el-icon-search" @input="handleSearch" size="small"
          style="width: 300px; flex: 1 1 0%; margin-right: 0.5rem;" placeholder="Search By Company Name..." />
        <el-button style="flex: 0 1 0%;" size="small" icon="el-icon-refresh" @click="refreshTable()" type="warning">
          Refresh Table
        </el-button>
      </div>
    </div>
    <el-table :data="companies === null ? [] : companies" :show-action-bar="false" :filters="[searchDef]"
      :current-page="currentPage" :total="totalItems" style="width: 100%" v-loading="loading" class="tracker-table">
      <el-row class="table-header" slot="tool">
        <el-col class="table-header-name">
          <span>Tracker Settings</span>
        </el-col>
        <el-col class="table-header-container">
          <div style="flex: 1">
            <el-input v-model="searchDef.value" placeholder="Search By Company Name..." size="small">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div style="flex: 0; margin-left: 0.5rem">
            <el-button @click="refresh" type="primary" size="small">
              <font-awesome-icon icon="sync-alt" :spin="loading" fixed-width></font-awesome-icon>
              Refresh Table
            </el-button>
          </div>
        </el-col>
      </el-row>

      <el-table-column label="" width="60" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.tracker.active">
            <el-tooltip effect="dark" content="Tracker inactive." placement="bottom">
              <font-awesome-icon icon="times-circle" fixed-width style="color: #909399"></font-awesome-icon>
            </el-tooltip>
          </span>
          <span v-else-if="scope.row.tracker.paused">
            <el-tooltip effect="dark" content="Tracker Paused." placement="bottom">
              <font-awesome-icon icon="pause-circle" fixed-width style="color: #e6a23c"></font-awesome-icon>
            </el-tooltip>
          </span>
          <span v-else>
            <el-tooltip effect="dark" content="Tracker active." placement="bottom">
              <font-awesome-icon icon="check-circle" fixed-width style="color: #67c23a"></font-awesome-icon>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="Company" sortable="custom" min-width="150"
        show-overflow-tooltip></el-table-column>

      <el-table-column label="" width="250" class-name="tracker-services-cell">
        <template slot-scope="scope">
          <template v-if="scope.row.tracker.active">
            <img :src="getServiceLogo('google-display')" class="service-logo" :class="{
          inactive:
            !('google-display' in scope.row.tracker.services) &&
            !('google-sem' in scope.row.tracker.services) &&
            !('google-video' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('bing-sem')" class="service-logo" :class="{
          inactive: !('bing-sem' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('facebook-display')" class="service-logo" :class="{
          inactive:
            !('facebook-display' in scope.row.tracker.services) &&
            !('facebook-video' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('simplifi-display')" class="service-logo" :class="{
          inactive:
            !('simplifi-display' in scope.row.tracker.services) &&
            !('simplifi-tv' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('siteimpact-mail')" class="service-logo" :class="{
          inactive: !('siteimpact-mail' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('mailchimp-mail')" class="service-logo" :class="{
          inactive: !('mailchimp-mail' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('tremor-tv')" class="service-logo" :class="{
          inactive: !('tremor-tv' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('emailresultz-mail')" class="service-logo" :class="{
          inactive: !('emailresultz-mail' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('constantcontact-mail')" class="service-logo" :class="{
          inactive: !('constantcontact-mail' in scope.row.tracker.services),
        }" />
            <img :src="getServiceLogo('settop-tv')" class="service-logo" :class="{
          inactive: !('settop-tv' in scope.row.tracker.services),
        }" />
          </template>
          &nbsp;
        </template>
      </el-table-column>

      <el-table-column label="" width="100" align="center">
        <template slot-scope="scope">
          <router-link :to="{
          name: 'admin_tracker_company',
          params: { company: scope.row.slug },
        }">
            {{ scope.row.tracker.active ? "Edit" : "Set up" }}
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="20" class="pagination"
      layout="prev, pager, next" :total="totalItems">
    </el-pagination>
  </div>
</template>


<style lang="scss">
.set-up-tracker {
  text-align: center;

  p {
    margin-bottom: 0.5rem;
  }
}

.el-table__expanded-cell[class*="cell"] {
  background: #fafafa;
  padding: 1rem 2rem;
  font-size: 0.9rem;

  p {
    font-size: inherit;
  }
}

.pagination {
  text-align: center;
}

.table-title {
  display: flex;
  border-bottom: 2px solid #d4a100;
  padding-right: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #303133;
}

.table-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.table-toolbar {
  display: flex;
  justify-content: flex-end;
  width: 500px;
  max-width: 100%;
  background: #fafafa;
  padding: .25rem .5rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
}
</style>


<script>
import TrackerCreateForm from "@/components/Forms/TrackerCreateForm"
import DateTime from "@/mixins/DateTime"
import TrackerServiceLogos from "@/mixins/TrackerServiceLogos"
import { mapActions, mapGetters } from "vuex"
import { debounce } from 'lodash'

export default {
  name: "TrackerTable",

  mixins: [DateTime, TrackerServiceLogos],
  components: {},

  data() {
    return {
      searchDef: {
        value: "",
        prop: "name",
      },
      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      loading: false,
      currentPage: 1,
      search: '',
    }
  },
  computed: {
    ...mapGetters('companies', ['list', 'totalItems']),
    companies() {
      return this.list;
    }
  },
  created() {
    this.fetchData(this.currentPage);
  },
  methods: {
    ...mapActions('companies', ['refresh']),
    fetchData(page = 1, search = this.search) {
      this.refresh({ page, query: search });
    },
    refreshTable() {
      this.search = '';
      this.currentPage = 1;
      this.fetchData(this.currentPage, this.search);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData(page);
    },
    handleSearch: debounce(function () {
      this.currentPage = 1;
      this.fetchData(this.currentPage);
    }, 500),
    openCreateTrackerModal(row) {
      this.$modal.show(
        TrackerCreateForm,
        {
          existing: row,
        },
        {
          name: "tracker-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
  },
  watch: {
    data(val) {
      this.loading = val === null;
    },
  },
}
</script>
